<template>
  <div>

    <Table
         stripe :data="list" :columns="tableColumns" @on-selection-change="selectionChange"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary></Table>
 <Row>
      <i-col span='12'>
        <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span>
        </i-col>
      <i-col  span='12'>
        <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-sizer
        :page-size-opts="[15,50,100,200,500]"
        :current="query.pageNumber"
        @on-change="handlePageChanged"
        @on-page-size-change="handlePageSizeChanged"></Page>
    </div>
      </i-col>
    </Row>

    <Update ref="updateModal"></Update>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
// import { getContractPage } from '@/api/scp/contract'
import { getsettlementdetailpage } from '@/api/msp/settlement'
import { formatMainanceReconcilliationStatus } from '@/utils/tagStatus'
import Update from './components/Update'

export default {
  components: {
    Update
  },
  props: {
    params: Object
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,

      query: {
        pageNumber: 1,
        pageSize: 15,
        settlementCompanyId: 0,
        publisherId: 0,
        startDate: '',
        endDate: '',
        status: null,
        keyword: ''
      },
      list: [],
      tableColumns: [
        {
          type: 'selection',
          width: 65,
          key: 'id',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          width: 120,
          fixed: 'left',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'companyName',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.stationName)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },
        {
          title: '客户',
          width: 120,
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.brandName || '-')
            ])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.adTypeName || '-')
            ])
          }
        },
        {
          title: '计费数量',
          key: 'chargeQuantity',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeQuantity + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '单价',
          key: 'unitCost',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.formatUnitCost + '/' + params.row.chargeQuantityUnit)])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '作业日期',
          key: 'actionDate',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.actionDate)])
          }
        },
        {
          title: '作业完成时间',
          key: 'finishTime',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.finishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityTestTime',
          width: 120,
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityTestTime)])
          }
        },
        // {
        //   title: '可对账',
        //   width: 80,
        //   align: 'center',
        //   render: (h, data) => {
        //     if (data.row.qualityTesting) {
        //       return h('Icon', {
        //         props: { type: 'md-checkbox-outline', size: 16, color: '#19be6b' }
        //       })
        //     } else {
        //       return h('Icon', {
        //         props: { type: 'md-close', size: 16, color: '#ed4014' }
        //       })
        //     }
        //   }
        // },
        {
          title: '备注',
          key: 'remark',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.remark || '-')
            ])
          }
        },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatMainanceReconcilliationStatus(
              h,
              data.row.status,
              data.row.statusName
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 80,
          fixed: 'right',
          render: (h, params) => {
            return h('div', [
              this.isAuth('maintenance_editReconciliation') ? h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.updateModal.showModal(params.row)
                    }
                  }
                },
                '修改'
              ) : null
            ])
          }
        }
      ],

      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合

      currentPageSelectionIdArray: []
    }
  },
  created () {
    this.$store.commit('set_reconciliationNew_feeitemIds', null)
    this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', 0)
    this.query.publisherId = this.publisherId
    this.clientHeight = document.documentElement.clientHeight - 220
  },
  computed: {
    companyId () {
      return this.$store.state.reconciliationNew.companyId
    },
    publisherId () {
      return this.$store.state.reconciliationNew.publisherId
    },
    maintenanceReconciliationQuery () {
      return this.$store.state.reconciliationNew.maintenanceReconciliationQuery
    },
    notAllowReconciliationQuantity () {
      return this.$store.state.reconciliationNew.notAllowReconciliationQuantity || 0
    },
    feeitemIds () {
      return this.$store.state.reconciliationNew.feeitemIds || []
    },
    beginUpdate () {
      return this.$store.state.reconciliationNew.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.$store.commit('set_reconciliationNew_feeitemIds', [])
      this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', 0)
      if (this.maintenanceReconciliationQuery.supplierCompanyId) {
        this.query.supplierCompanyId = this.maintenanceReconciliationQuery.supplierCompanyId
        this.query.settlementCompanyId = this.maintenanceReconciliationQuery.settlementCompanyId
        this.query.startDate = this.maintenanceReconciliationQuery.startDate
        this.query.endDate = this.maintenanceReconciliationQuery.endDate
        this.query.startActionDate = this.maintenanceReconciliationQuery.startActionDate
        this.query.endActionDate = this.maintenanceReconciliationQuery.endActionDate
        this.query.keyword = this.maintenanceReconciliationQuery.keyword
        this.query.minCost = this.maintenanceReconciliationQuery.minCost
        this.query.maxCost = this.maintenanceReconciliationQuery.maxCost
        this.query.status = this.maintenanceReconciliationQuery.status === -1 ? null : this.maintenanceReconciliationQuery.status
        this.query.taskitemTypes = this.maintenanceReconciliationQuery.taskitemTypes
        this.query.resourcetypeIds = this.maintenanceReconciliationQuery.resourcetypeIds
      }
      this.reloadPage()
    }

  },
  methods: {
    reloadPage () {
      this.query.pageNumber = 1
      if (this.query.supplierCompanyId) {
        this.loadPage()
      }
    },
    loadPage () {
      const that = this
      getsettlementdetailpage(that.query).then((res) => {
        if (res.list.length) {
          res.list.map((item) => {
            item.visible = item.visibleScope === 2
            item._checked = that.feeitemIds.includes(item.id)
            item.formatUnitCost = toMoney(item.unitCost)
            // item._disabled = (item.status === 2)
            return item
          })
          that.currentPageAllItemIds = res.list.map(item => {
            return item.id
          })
        }

        that.list = res.list

        that.total = res.totalRow
      })
    },
    handleSummary ({ columns, data }) {
      const needSummarykey = ['amount', 'signUsePrice', 'producePrice', 'installPrice']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.reloadPage()
    },
    selectionChange (selection) {
      const that = this
      var quantity = that.notAllowReconciliationQuantity
      const selectionIds = selection.map(item => {
        return item.id
      })
      // 循环当前页面所有列
      that.currentPageAllItemIds.forEach(item => {
        // 列是否是选中状态
        const currentItem = that.list.filter(x => x.id === item)[0]
        if (selectionIds.includes(item)) {
          // 判断是否已在列表，不在则加入
          if (!that.feeitemIds.includes(item)) {
            that.feeitemIds.push(item)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity + 1
            }
          }
        } else { // 列未被选中
          // 判断是否已在列表，在则移除
          if (that.feeitemIds.includes(item)) {
            that.feeitemIds.splice(that.feeitemIds.indexOf(item), 1)
            if (!currentItem.qualityTesting || currentItem.status === 2) {
              quantity = quantity - 1
            }
          }
        }
      })
      this.$store.commit('set_reconciliationNew_feeitemIds', that.feeitemIds)
      this.$store.commit('set_reconciliationNew_notAllowReconciliationQuantity', quantity)
    }
  }
}
</script>
